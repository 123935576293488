<template>
  <div class="relative">
    <InnerHeader />
    <div class="flex flex-col lg:flex-row ais jcb main-container pt-6 lg:border-t border-gray-100 relative px-10 md:px-0 md:mt-8 lg:mt-14">
      <h1 class="min-w-max text-base lg:text-xl xl:text-2xl merienda-bold uppercase order-2 md:order-1 mt-2 md:mt-0 text-brown-light">{{$t('Blogs')}}</h1>
      <ol class="min-w-max flex aic space-x-3 text-xs order-1 md:order-2">
        <li>
          <router-link
            to="/"
            class="nunitoSans-regular text-gray-500"
          >{{$t('Home')}}</router-link>
        </li>
        <li class="text-gray-400"><i class="fa fa-angle-right"></i></li>
        <li class="nunitoSans-bold">{{$t('Blogs')}}</li>
      </ol>
    </div>

    <div class="main-container mt-8 flex flex-wrap ais px-8 md:px-0">
      <div class="flex w-full bg-yellow-50 mb-44 opacity-90 mt-5" v-if="!blogs || !blogs.length">
        <span class="bg-brown-lighter h-12 md:h-16 fcc w-10 text-brown-light">
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="currentColor" class="transform scale-75 md:scale-100">
            <path d="M12 2c5.514 0 10 4.486 10 10s-4.486 10-10 10-10-4.486-10-10 4.486-10 10-10zm0-2c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm-2.033 16.01c.564-1.789 1.632-3.932 1.821-4.474.273-.787-.211-1.136-1.74.209l-.34-.64c1.744-1.897 5.335-2.326 4.113.613-.763 1.835-1.309 3.074-1.621 4.03-.455 1.393.694.828 1.819-.211.153.25.203.331.356.619-2.498 2.378-5.271 2.588-4.408-.146zm4.742-8.169c-.532.453-1.32.443-1.761-.022-.441-.465-.367-1.208.164-1.661.532-.453 1.32-.442 1.761.022.439.466.367 1.209-.164 1.661z"/>
          </svg>
        </span>
        <span class="flex aic jcs px-3 text-brown opacity-70 text-xs sm:text-sm">{{$t('No blog has been posted yet !')}}</span>
      </div>
      <div
        class="w-full md:w-1/2 xl:w-1/3 lg:pr-4 mb-20"
        v-for="(blog, i) in blogs"
        :key="i"
      >
 
        <router-link
          :to="`/blogs/${blog.id}`"
          class="w-full flex ais jcs relative"
        >
          <div class="relative solid-image">
            <img
              :src="storageUrl + '' + blog.image"
              alt="#"
              class="h-300p w-full object-cover mb-4 shadow-xl"
            />
            <span class="text-xs">{{10 > i+1? `0${i+1}`:`${i+1}`}}.</span>
            <div class="title nunitoSans-bold text-sm text-gray-900">
              {{ blog.title }}
            </div>
          </div>
          <div class="flex w-20p aic jcb text-sm sample absolute top-0">
            <div class="text-gray-900 relative inline-block mr-2">
              <svg
                width="24"
                height="24"
                xmlns="http://www.w3.org/2000/svg"
                fill-rule="evenodd"
                clip-rule="evenodd"
              >
                <path d="M0 12v1h23v-1h-23z" />
              </svg>
            </div>
            <span class="whitespace-nowrap dmSans-medium fs-11 categories-in">{{blog.created_at.split('T')[0]}}</span>
          </div>
        </router-link>
      </div>

    </div>
    <MainFooter />
  </div>
</template>
<script>
import InnerHeader from "../components/InnerHeader.vue";
import MainFooter from "../components/MainFooter.vue";
export default {
  components: { InnerHeader, MainFooter },
  computed: {
    blogs() {
      return this.$store.state.orderiomApiPackage.restaurant.blogs;
    },
    storageUrl() {
      return process.env.VUE_APP_STORAGE_URL;
    },
  },
  created() {
    this.$store.dispatch("restaurant/getBlogs");
  },
};
</script>
<style lang="">
</style>